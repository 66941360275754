<template>
    <div>
        <div class="row">
            <div class="col">Manche</div>
            <div class="col"><input type="number" v-model="round_number" /></div>
        </div>
    </div>

</template>

<script>
export default{

}
</script>